import React, { Component } from 'react';
import {connect} from 'react-redux';

import OfflineForm from './forms/OfflineForm';
import NoDataErrorForm from './forms/NoDataErrorForm';
import SendCodeForm from './forms/SendCodeForm';
import EnterCodeForm from './forms/EnterCodeForm';
import FormContainer from "./components/FormContainer";
import FormLoader from './components/FormLoader';
import {sendAnalytics} from "./api/sendAnalyticsAPI";


import { redirecToSignIn } from './helpers/redirects';
import { ExtractUrlData } from './helpers/urls';
import {setActiveForm, generatePortalCode, checkPortalUser, sendPortalCode, loadPortalData, setPortalSsoCode} from './actions';
import * as f from './constants/formTypes';
import * as s from './PortalSignIn.styles';
import SniperLinkForm from './forms/SniperLinkForm';
import EnterEmailForm from './forms/EnterEmailForm';
import { hexToHSL, HSLToHex, ShadeAndTintHex } from './helpers/colorTransformations';
import { colors } from "./base";

export class PortalSignIn extends Component {

  constructor(props) {
    super(props);

    this.state = { 
      displayLoading: false,
      attemptCount: 0,
      lastAttemptDate: 0,
    };
  }
  
  shouldComponentUpdate(nextProps) {
    if (
      nextProps.PortalBrandingColor !== this.props.PortalBrandingColor
    ) {
      const portalStyleTagId = 'portal-branding-styles';

      /* Creation of the css styles */
      const { lowerColor, upperColor } = ShadeAndTintHex(nextProps.PortalBrandingColor, 10);
      const hslColor = hexToHSL(nextProps.PortalBrandingColor);
      
      const css = `
        /* Background styles */
        .portal-signin-background {
          background-color: ${HSLToHex(hslColor.h, hslColor.s, 97)};
        }

        /* Panel styles */
        .portal-signin-panel {
          background: linear-gradient(210.98deg, ${lowerColor} 1.09%, ${upperColor} 101.6%);
        }

        /* Font styles */
        .portal-branding-font {
          color: ${(hslColor.l > 65) ? colors['grey-dark'] : colors.white} !important;
        }

        /* Svg styles */
        .portal-branding-filling {
          fill: ${(hslColor.l > 65) ? colors['grey-dark'] : colors.white};
        }

        .portal-branding-stroke {
          stroke: ${nextProps.PortalBrandingColor};
        }

        /* Icon styles */
        .portal-branding-icon {          
          background-color: ${HSLToHex(hslColor.h, hslColor.s, 95)};         
        } 
        .portal-branding-icon > svg > path {
          fill: ${HSLToHex(hslColor.h, hslColor.s, 25)};
        }

        /* Button styles */
        .portal-branding-button.primary {
          background-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, hslColor.l - 5) : nextProps.PortalBrandingColor};
          border-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, hslColor.l - 40) : nextProps.PortalBrandingColor};
          color: ${(hslColor.l > 65) ? colors['grey-dark'] : colors.white};
        }
        .portal-branding-button.primary:hover {
          background-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, (hslColor.l - 5) - 10) : HSLToHex(hslColor.h, hslColor.s, hslColor.l + 20)};
          border-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, (hslColor.l - 40) - 10) : HSLToHex(hslColor.h, hslColor.s, hslColor.l + 20)};
        }
        .portal-branding-button.primary:disabled:hover {
          background-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, hslColor.l - 5) : nextProps.PortalBrandingColor};
          border-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, hslColor.l - 50) : nextProps.PortalBrandingColor};
          color: ${(hslColor.l > 65) ? colors['grey-dark'] : colors.white};
        }

        .portal-branding-button.secondary {
          background-color: ${colors.white};
          border-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, hslColor.l - 25) : nextProps.PortalBrandingColor};
          color: ${(hslColor.l > 65) ? colors['grey-dark'] : nextProps.PortalBrandingColor};
        }
        .portal-branding-button.secondary:hover {
          background-color: ${colors.white};
          border-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, (hslColor.l - 25) - 10) : HSLToHex(hslColor.h, hslColor.s, hslColor.l + 20)};
          color: ${(hslColor.l > 65) ? colors['grey-dark'] : HSLToHex(hslColor.h, hslColor.s, hslColor.l + 20)};
        }
        .portal-branding-button.secondary:disabled:hover {
          background-color: ${colors.white};
          border-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, hslColor.l - 25) : nextProps.PortalBrandingColor};
          color: ${(hslColor.l > 65) ? colors['grey-dark'] : nextProps.PortalBrandingColor};
        }

        /* Input styles */
        .portal-branding-signin-email-input {
          border-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, hslColor.l - 25) : nextProps.PortalBrandingColor} !important;
        }
        .portal-branding-signin-email-input:focus ~ .portal-branding-signin-email-label, .portal-branding-signin-email-input:focus-within ~ .portal-branding-signin-email-label {
          color: ${(hslColor.l > 65) ? colors['grey-dark'] : nextProps.PortalBrandingColor} !important;
        }
        .portal-branding-signin-email-active {
          color: ${(hslColor.l > 65) ? colors['grey-dark'] : nextProps.PortalBrandingColor} !important;
        }
        .portal-branding-code-input:focus {
          border-color: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, (hslColor.l - 40) - 10) : HSLToHex(hslColor.h, hslColor.s, hslColor.l + 20)} !important;
        }
        .portal-branding-checked {
          background: ${(hslColor.l > 65) ? HSLToHex(hslColor.h, hslColor.s, hslColor.l - 5) : nextProps.PortalBrandingColor};
        }
      `;
      
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');  
      
      /* Deletion of existing styles */
      const existingBrandingStyle = document.getElementById(portalStyleTagId);
      
      if(existingBrandingStyle)
        head.removeChild(existingBrandingStyle);

      /* Added of the created css styles */
      head.appendChild(style);

      style.setAttribute('id', portalStyleTagId)
      style.type = 'text/css';
      if (style.styleSheet){
        // This is required for IE8 and below.
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
    }

    return true;
  }

  componentDidMount() {
    const { pName, pQueryCode, pSsoCode, hasInvalidQuery } =  new ExtractUrlData(window.location.href);

    // query is used to pass in the code for users coming directly through email
    if (hasInvalidQuery) {
      redirecToSignIn(pName);
    } else {
      this.props.loadPortalData(pName, pQueryCode, pSsoCode);
      // 1. get portalData
      // 2. next check if there're valid cookies
        // yes? redirect to portal app
        // 3. is there a code query in url
            // yes? go to enter code form
            // no? go to send email form
    }

  }
  
  onSSO = async (handleSubmitError) => {
    const { 
      portalSsoCode,
      setPortalSsoCode
    } = this.props;

    const status = await this.onSendCode('', '', portalSsoCode, handleSubmitError);
    
    // Clean sso either if went good or bad
    setPortalSsoCode('');

    return status;
  };


  onGetUser = async (userEmail, handleSubmitError) => {
    const { AccountId } = this.props;
    
    let status = await this.props.checkPortalUser({
      AccountId,
      userEmail,
      handleSubmitError
    });
    
    if (status >= 200 && status < 300) {
      // Disabled until we have more options beside email
      // this.props.setActiveForm(f.SEND_CODE_BY_CHANNEL);

      status = await this.onSendEmail('', 'email', handleSubmitError);
    }

    return status;
  };

  onSendEmail = async (userPhone, picked, handleSubmitError, autoChangeForm = true) => {
    const { AccountId, userEmail, userSniperLink, PortalBrandingColor } = this.props;

    const { h, s } = hexToHSL(PortalBrandingColor)
    const status = await this.props.generatePortalCode({
      AccountId,
      userEmail,
      userPhone,
      picked,
      userSniperLink,
      handleSubmitError,
      BrandingColor: `${h}, ${s}%`,
    }); // returns a promise back to formik

    if (status >= 200 && status < 300) {
      if (autoChangeForm) {
        if (picked === 'email' && userSniperLink && userSniperLink.length > 0){
        this.props.setActiveForm(f.SNIPER_LINK);
        } else {      
        this.props.setActiveForm(f.ENTER_CODE);
        }
      }

      // reset attempt count to 0 on successful send
      this.setState({ attemptCount: 0 });
      this.setState({ lastAttemptDate: 0 });
    }

    return status;
  };

  onSendCode = async (portalCode, stayOn, portalSsoCode, handleSubmitError) => {
    const { AccountId, userEmail, sendPortalCode, PortalName } = this.props;
    const { attemptCount } = this.state;

    const status = await sendPortalCode({
      AccountId,
      PortalName,
      userEmail,
      portalCode,
      stayOn,
      portalSsoCode,
      handleSubmitError
    }); // returns a promise back to formik

    if (status >= 200 && status < 300) {
      this.setState({ displayLoading: true });
    } else {
      // only increase attempt count on valid attempts (request passes form validation)
      const incrementedCount = attemptCount + 1;
      this.setState({ attemptCount: incrementedCount });
      this.setState({ lastAttemptDate: Date.now() });
    }

    return status;
  };

  onSniperLink = () => {   
    const { userSniperLink } = this.props;

    this.props.setActiveForm(f.ENTER_CODE); 
    
    if (userSniperLink) {
      const currentDate = new Date();
      const userSniperLinkUncoded = String(userSniperLink).replace(
        "[DATE]", 
        `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}-${String(currentDate.getDate()).padStart(2, '0')}`
      );
      const userSniperLinkEncoded = encodeURI(userSniperLinkUncoded);
      
      window.open(userSniperLinkEncoded, '_blank');
    }
  }

  handleFailedAttemptsCountdownStop = () => {
    const { setActiveForm, resetForm } = this.props;
    if (resetForm) {
      resetForm();
    }

    setActiveForm(f.ENTER_EMAIL);
    
    this.setState({ attemptCount: 0 });
    this.setState({ lastAttemptDate: 0 });
  }

  onBackPress = () => { 
    if ( this.props.portalQueryCode ) {
      // clear the url on back press
      redirecToSignIn(this.props.PortalName) 
    } else {
      this.props.setActiveForm(f.SEND_CODE_BY_CHANNEL);
    }
  };
  
  onBackPress = () => {
    const { 
      activeForm,
      setActiveForm,
      userSniperLink,
    } = this.props;
    
    switch(activeForm) {
      case f.SNIPER_LINK:
        // clear the url on back press
        if (this.props.portalQueryCode) {
          redirecToSignIn(this.props.PortalName);
          break;
        }
        setActiveForm(f.ENTER_EMAIL);
        break;

      case f.ENTER_CODE:
        if (userSniperLink && userSniperLink.length > 0) {
          setActiveForm(f.SNIPER_LINK);
          break;
        }

        // clear the url on back press
        if (this.props.portalQueryCode) {
          redirecToSignIn(this.props.PortalName);
          break;
        }
        setActiveForm(f.ENTER_EMAIL);
        break;

      default:
        break;
    }    
  };

  onNextPress = () => {
    const { 
      activeForm,
      setActiveForm,
    } = this.props;
    
    switch(activeForm) {
      case f.SNIPER_LINK:
        setActiveForm(f.ENTER_CODE);
        break;

      default:
        break;
    }    
  };

  onContactClick = () => { 
    const {AccountId, Name, isPortalEnabled, isActive} = this.props;
    sendAnalytics("Portal Contact Us Link Clicked",AccountId,Name,isPortalEnabled,isActive,document.referrer);
  };
  
  isBackPressable = () => {    
    const { 
      activeForm,
    } = this.props;

    return activeForm === f.SNIPER_LINK ||
      activeForm === f.ENTER_CODE;
  }

  isNextPressable = () => {    
    const { 
      activeForm,
    } = this.props;

    return activeForm === f.SNIPER_LINK;
  }

  renderForms = formType => {
    const {
      Name, 
      PortalContactEmail,
      PortalLogo,
      PortalLogoSignInVisible,
      PortalCompanyName,
      AccountId, 
      formErrorId, 
      portalQueryCode,
      userEmail,
      userEmailProvider,
      portalSsoCode,
    } = this.props;
    window.portalAccount = Name;

    const { 
      attemptCount,
    } = this.state;

    switch( formType ) {      
      case f.NO_DATA:
        // NoDataErrorForm gets its error id from the reducer.
        // validation error messages inside the forms however, 
        // get their error id directly from the response right after handleSubmit
        return <NoDataErrorForm formErrorId={formErrorId}/>

      case f.ENTER_EMAIL:                
        return (
          <EnterEmailForm            
            companyLogo={PortalLogoSignInVisible ? PortalLogo : ""}
            companyName={PortalCompanyName}
            accountId={AccountId}
            ssoCode={portalSsoCode}
            handleEnterEmail={this.onGetUser}
            handleSso={this.onSSO}
            handleBackPress={this.onBackPress}
            handleNextPress={this.onNextPress}
            isBackPressable={this.isBackPressable}
            isNextPressable={this.isNextPressable}
          />
        );

      case f.SEND_CODE_BY_CHANNEL:
        return (
          <SendCodeForm
            accountId={AccountId}
            handleSendEmail={this.onSendEmail}
            email={userEmail}
            // phone={(userPhones && userPhones.length > 0) ? userPhones[0] : null}
          />
        );

      case f.SNIPER_LINK:
        return (
          <SniperLinkForm
            provider={userEmailProvider}
            email={userEmail}
            handleSniperLink={this.onSniperLink}
            handleBackPress={this.onBackPress}
            handleNextPress={this.onNextPress}
            isBackPressable={this.isBackPressable}
            isNextPressable={this.isNextPressable}
          />
        );

      case f.ENTER_CODE:
        return (
          <EnterCodeForm 
            queryCode={portalQueryCode}
            email={userEmail}
            handleSendEmail={this.onSendEmail}
            handleSendCode={this.onSendCode}
            handleBackPress={this.onBackPress}
            handleNextPress={this.onNextPress}
            isBackPressable={this.isBackPressable}
            isNextPressable={this.isNextPressable}
            attemptCount={attemptCount}
            lastAttemptDate={this.state.lastAttemptDate}
            handleFailedAttemptsCountdownStop={this.handleFailedAttemptsCountdownStop}
          />
        );

      case f.OFFLINE: 
        return (
          <OfflineForm 
            companyEmail={PortalContactEmail} 
            companyName={PortalCompanyName}
            handleContactClick={this.onContactClick}
          />
        )
      
      default:
        return null;
    }
  }

  render() {
    const {
      activeForm,
    } = this.props;

    const { 
      displayLoading,
    } = this.state;

    return (
      <s.PortalSignInWrapper className="portal-signin-background">
        {activeForm && !((activeForm === f.ENTER_CODE || activeForm === f.ENTER_EMAIL) && displayLoading) && (
          <FormContainer 
            activeForm={activeForm}
          >
            { this.renderForms(activeForm) }
          </FormContainer>
        )}
        {((activeForm === f.ENTER_CODE || activeForm === f.ENTER_EMAIL) && displayLoading) && (
          <FormLoader />
        )}
      </s.PortalSignInWrapper>
    );
  }
}


function mapStateToProps({activeFormReducer, portalDataReducer}) {
  return {
    ...activeFormReducer,
    ...portalDataReducer
  }
}

export default connect(
  mapStateToProps, 
  {setActiveForm, generatePortalCode, checkPortalUser, sendPortalCode, loadPortalData, setPortalSsoCode}
)(PortalSignIn);
